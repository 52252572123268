import React, { useEffect } from "react";

function App() {
    useEffect(() => {
        window.location.href = "https://www.talentx.co.kr/talentx/promotion";
    }, []);
    return (
        <div style={{ textAlign: "center" }}>
            <p>Connecting...</p>
        </div>
    );
}

export default App;
